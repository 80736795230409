/**
 * 与作业有关的接口
 * 2021-11-21
 * author DuZeFang
 */
import request from '@/utils/request'

export default class WorkApi {
    // 作业列表
    static index(params) {
        return request({
            url: '/work/index',
            method: 'post',
			data: params
        })
    }

    // 课程下作业详情
    static course(id, params) {
        return request({
            url: `/work/course/${id}`,
            method: 'post',
			data: params
        })
    }

    // 作业详情
    static info(id, params) {
        return request({
            url: `/work/info/${id}`,
            method: 'post',
			data: params
        })
    }

    // 老师留作业
    static store(params) {
        return request({
            url: '/work/store',
            method: 'post',
			data: params
        })
    }

    // 学生提交作业
    static submit(id, params) {
        return request({
            url: `/work/submit/${id}`,
            method: 'post',
			data: params
        })
    }

    // 老师批改作业
    static correct(id, params) {
        return request({
            url: `/work/correct/${id}`,
            method: 'post',
			data: params
        })
    }

    // 老师批删除作业
    static delete(id, params) {
        return request({
            url: `/work/delete/${id}`,
            method: 'post',
			data: params
        })
    }
}
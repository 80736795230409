<template>
	<div id="tu-work-detail">
		<el-row :gutter="20">
			<el-col :span="4">
		    <SecondMenu />
			</el-col>
			<el-col :span="20">
				<div class="warp">
					<div class="desc-style">
						<div>kooriookami</div>
						<div><el-button size="mini" @click="$router.go(-1)">Go Back</el-button></div>
					</div>
					<el-descriptions>
					    <el-descriptions-item label="Username">kooriookami</el-descriptions-item>
					    <el-descriptions-item label="Telephone">18100000000</el-descriptions-item>
					    <el-descriptions-item label="Place">Suzhou</el-descriptions-item>
					    <el-descriptions-item label="Remarks">
					      <el-tag size="small">School</el-tag>
					    </el-descriptions-item>
					    <el-descriptions-item label="Address"
					      >No.1188, Wuzhong Avenue, Wuzhong District, Suzhou, Jiangsu
					      Province</el-descriptions-item
					    >
					 </el-descriptions>
				</div>
		  	<el-row :gutter="0" class="tu-row-div">
					<el-col :span="8" class="tu-row-col">
						<div class="cell">
							<div>Sat 25th Sep 2021</div>
							<div>60 mins</div>
							<div>09:00 - 10:00</div>
						</div>
					</el-col>
					<el-col :span="8" class="tu-row-col">
						<div class="cell">
							<div class="money">￡1800</div>
							<div>Amount</div>
						</div>
					</el-col>
					<el-col :span="8" class="tu-row-col">
						<div class="cell">
							<div>Tutoredin's Zoom</div>
							<div>
								<el-popover
							    placement="top-start"
							    title="Title"
							    :width="200"
							    trigger="hover"
							    content="this is content, this is content, this is content"
							  >
							    <template #reference>
							      <el-button>Hover to activate</el-button>
							    </template>
							  </el-popover>
							</div>
						</div>
					</el-col>
		  	</el-row>
		  	<div class="warp">
					<el-row class="btn">
				    <el-button>Default</el-button>
				    <el-button type="primary">Primary</el-button>
				    <el-button type="success">Success</el-button>
				    <el-button type="info">Info</el-button>
				    <el-button type="warning">Warning</el-button>
				    <el-button type="danger">Danger</el-button>
				    <el-button>中文</el-button>
				  </el-row>
				</div>
			</el-col>
		</el-row>
	</div>
</template>
<script>
import SecondMenu from '@/components/SecondMenu'
import WorkApi from '@/api/work'

export default {
	name: "work-detail",
	components: {
		SecondMenu
	},
	data() {
		return {
			id: 0,
			detail: null
		}
	},
	created() {
		this.id = this.$route.params.id;
		this.getApiDetailData()
	},
	methods: {
		getApiDetailData() {
			if(!!!this.id || /^\d+$/.test(this.id) === false) {
				this.$message.error("请求异常")
				this.$router.push({path: '/work'})
				return ;
			}
			WorkApi.info(this.id).then((res) => {
				this.detail = res.data
			});
		}
	}
}
</script>
<style lang="less">
#tu-work-detail {

	.warp {
		border: 1px solid #CCC;
	  padding: 15px 10px;
	  margin-right: 2px;

	  .desc-style {
	  	display: flex;
	  	justify-content: space-between;
	  	padding: 12px 0;

	  	div:first-child {
	  		color: var(--el-color-primary);
	  		font-size: 21px;
	  	}
	  }

	  .btn {
	  	justify-content: flex-end;
	  }
	}

	.tu-row-div {
		.tu-row-col {
			border: var(--el-border-base);
			margin-right: -1px;
			margin-top: -1px;
			margin-bottom: -1px;

			.cell {
				display: flex;
		    align-items: center;
		    color: gray;
		    flex-direction: column;
		    height: 290px;
		    justify-content: center;
		    line-height: 1.8;
			}

			.money {
				font-size: 60px;
				font-weight: var(--el-font-weight-primary);
			}
		}
	}
}
</style>
<template>
	<div class="block tu-second-menu">
		<div class="title">{{ type == 1 ? $t('router.personal-center') : $t('menu') }}</div>
		<div class="text item" v-for="item, index in second_menu" :key="index" :value="item.value" 
			:class="{active: item.value==value, disabled: item.disabled||false}" 
			@click="handleSelect(item)">
			{{ $t(item.name) }}
		</div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { ElMessageBox } from 'element-plus'

export default {
	name: 'SecondMenu',
	props: {
		title: {
			type: String,
			default: "Menu"
		},
		// 菜单展示类型，默认0业务菜单，1个人中心菜单
		type: {
			type: [Number, String],
			default: 0
		}
	},
	data() {
		return {
			second_menu: [],
			value: 'dashboard',
			teacher_menu: [
				[
					{name: "router.dashboard", path: '/dashboard', value: 'dashboard'},
					{name: "router.chat", path: '/chat', value: 'chat'},
					{name: "router.student", path: '/student', value: 'student'},
					{name: "router.course", path: '/course', value: 'course'},
					{name: "router.work", path: '/work', value: 'work'},
					{name: "router.cost", path: '/cost', value: 'cost'},
					{name: "router.personal-center", path: '/tu/home', value: 'personal'},
					{name: "router.account", path: '/tu/account', value: 'account'},
					{name: "router.availability", path: '/tu/availability', value: 'availability'},
				],
				[
					{name: "router.tu-home", path: '/tu/home', value: '/tu/home'},
					// {name: "router.message", path: '/message', value: '/message'},
					{name: "router.holiday", path: '/tu/holiday', value: '/tu/holiday'},
					{name: "router.personal-information", path: '/tu/information', value: '/tu/information'},
					{name: "router.notification", path: '/notification', value: '/notification'},
					{name: "router.change-password", path: '/change-password', value: '/change-password'},
					{name: "router.sign-out", path: 'Logout'}
				]
			],
			student_menu: [
				[
					{name: "router.dashboard", path: '/dashboard', value: 'dashboard'},
					{name: "router.chat", path: '/chat', value: 'chat'},
					{name: "router.teacher", path: '/teacher', value: 'teacher'},
					{name: "router.course", path: '/course', value: 'course'},
					{name: "router.work", path: '/work', value: 'work'},
					// {name: "Earnings", path: '/cost', value: 'cost'},
					{name: "router.personal-center", path: '/st/home', value: 'personal'},
					{name: "router.find-a-tutor", path: '/find', index: 'find-a-tutor'}
				],
				[
					{name: "router.st-home", path: '/st/home', value: '/st/home'},
					// {name: "router.message", path: '/message', value: '/message'},
					{name: "router.st-information", path: '/st/information', value: '/st/information'},
					// {name: "router.coupon", path: '/coupon', value: '/coupon'},
					{name: "router.notification", path: '/notification', value: '/notification'},
					{name: "router.change-password", path: '/change-password', value: '/change-password'},
					{name: "router.sign-out", path: 'Logout'}
				]
			]
		}
	},
	created() {
		if(this.isLogin && !this.userInfo) {
			this.$store.dispatch('GetUserInfo')
		}
		if(this.userType == 2) {
			this.second_menu = this.teacher_menu[this.type]
			if(this.userInfo.is_pass != 1) {
        this.second_menu.forEach(function(value, key){
          const allowMenu = ['/tu/home', '/tu/information', '/tu/availability', '/tu/account'];
          if(allowMenu.indexOf(value.path) == -1) {
            value.disabled = true;
          }
        })
      }
		} else if(this.userType == 1) {
			this.second_menu = this.student_menu[this.type]
		}
		if(this.type == 1) {
			this.value = this.$route.path
		} else {
			this.value = this.$route.meta.index || 'home'
		}
	},
	computed: {
		...mapGetters([
			'isLogin', 'userInfo', 'userType'
		])
	},
	methods: {
		...mapActions(['Logout']),
		handleSelect(value) {
			if(value.path == 'Logout') {
				ElMessageBox.confirm(this.$t('whether logout'), 'Warning', {
					confirmButtonText: this.$t('OK'),
					cancelButtonText: this.$t('cancel'),
					type: 'warning',
				}).then(() => {
					this.Logout().then(res => {
						this.$router.push({ path: '/' })
					})
				})
			} else {
				this.$router.push({ path: value.path })
			}
		}
	}
}
</script>
<style lang="less">
.tu-second-menu {
	background: var(--el-color-white);
	.title {
		padding: 17px;
		background: var(--el-color-primary);
		color: #FFF;
		font-weight: 600;
		font-size: 16px !important;
	}

	.item {
		padding: 14px;
		border: 1px solid var(--el-color-info-lighter);
		cursor: pointer;
		border-top: 0px;
		font-size: small;
	}

	.item:hover {
		background: var(--el-color-info-lighter);
	}
	
	.item.active {
		background: var(--el-color-info-light);
	}

	.disabled {
		color: var(--el-color-info-light);
	}
}
</style>